<template>
  <v-card>
    <v-card-title>予約実績(編集)</v-card-title>
    <template>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div v-for="msg in apierror.messages" :key="msg">
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <template>
        <v-spacer></v-spacer>
        <v-form class="ma-6">
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="reserve_id">予約ID</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <label for="reserve_id">{{ reservedProgress.resv_id }}</label>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="reserve_id">商品ID</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <a for="reserve_id" href="javascript:void(0);" style="text-decoration: underline;" @click="openOverviewDialog">{{ reservedProgress.po_code }} ( {{ reservedProgress.po_id }} )</a>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="customer_id">取引先</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <a
                    for="reserve_id"
                    v-bind="attrs"
                    style="text-decoration: underline; color:#666666"
                    v-on="on"
                  >{{ reservedProgress.cust_abbr }}({{ reservedProgress.cust_id }})</a>
                </template>
                <span>{{ reservedProgress.cust_name }}</span><br>
                <span>{{ reservedProgress.cust_name_eng }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="reserved_dt">予約日時</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <label>{{ reservedProgress.resv_dt }}</label>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="reserved_weight">総重量</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <label for="reserved_weight">
                {{ formatData(reservedProgress.resv_weight) }}KG
              </label>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="reserved_amount">総金額</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <label for="reserved_amount">{{ reservedProgress.ccy_mark }} {{ formatPrice(reservedProgress.resv_amount) }}</label>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="resv_status">現在状態</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <label for="reserved_amount">{{ reservedProgress.resv_stat_desc }}</label>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="resv_status">予約判定</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-autocomplete
                id="resv_status"
                v-model="resv_stat"
                :items="resvStatuses"
                outlined
                dense
                clearable
                placeholder="予約状態"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                dense
                :headers="headers"
                :items="ProgressDetail"
                item-key="no"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:[`item.resv_stat_desc`]="{ item }">
                  <div>
                    {{ item.resv_stat_desc }} {{ getProcFrom(item.proc_from) }}
                  </div>
                </template>
                <template v-slot:[`item.proc_memo_cust`]="{ item }">
                  <div>
                    {{ item.proc_memo_cust }}
                  </div>
                </template>
                <template v-slot:[`item.proc_memo_admin`]="{ item }">
                  <div>
                    {{ item.proc_memo_admin }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="reserved_note">備考</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-textarea
                v-model="resv_note"
                name="resv_note"
                outlined
                dense
                placeholder="変更内容など記入"
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              offset-md="5"
              cols="12"
            >
              <v-btn
                color="primary"
                :loading="submitStatus"
                @click="submit()"
              >
                Submit
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                @click="cancelClick"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <ReservedPreview
          :id="reservedProgress.po_id"
          :resv-dialog="resvDialog"
          @closeForm="closeReservedPreivew"
        ></ReservedPreview>
      </template>
    </template>
  </v-card>
</template>

<script>
import {
  mdiPlus, mdiCalendar,
} from '@mdi/js'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'
import ReservedPreview from './ReservedPreview.vue'

export default {
  components: {
    ReservedPreview,
  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiCalendar,
    },
    resvDialog: false,

    submitStatus: false,
    ProgressDetail: [],
    changeFlag: false,
    apierror: [],
    resv_stat: '',
    resv_note: '',
    resvStatuses: [
      {
        text: '承認', value: 9,
      },
      {
        text: '却下', value: 8,
      },
    ],
    dialog: false,
    headers: [
      {
        text: 'No.',
        align: 'left',
        sortable: false,
        width: '5%',
        value: 'no',
      },
      {
        text: '処理日時',
        align: 'left',
        sortable: false,
        width: '19%',
        value: 'proc_dt',
      },
      {
        text: '操作詳細',
        align: 'left',
        sortable: false,
        width: '12%',
        value: 'resv_stat_desc',
      },
      {
        text: '数量',
        align: 'left',
        sortable: false,
        width: '8%',
        value: 'resv_cnt',
      },

      {
        text: '備考(取引先)',
        align: 'left',
        sortable: false,
        width: '28%',
        value: 'proc_memo_cust',
      },
      {
        text: '備考(GE)',
        align: 'left',
        sortable: false,
        width: '28%',
        value: 'proc_memo_admin',
      },
    ],
  }),
  computed: {
    ...mapState('productStore', ['productMaster', 'productIds']),
    ...mapState('reservedStore', ['resvStatusList', 'reservedProgress']),

    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
    formatPrice() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return parseFloat(data).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
    getProcFrom() {
      return function (data) {
        if (data === 1) {
          return '(取引先)'
        }
        if (data === 2) {
          return '(GE)'
        }

        return ''
      }
    },
  },
  watch: {
    resv_note(val) {
      this.changeFlag = true
      if (val === '') {
        this.changeFlag = false
      }
    },
    resv_stat(val) {
      this.changeFlag = true
      if (val === '') {
        this.changeFlag = false
      }
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('productStore', ['loadProductIds']),
    ...mapActions('reservedStore', ['editReserved', 'loadReservationStatus', 'loadReservedProgress']),
    ...mapMutations('app', ['setOverlayStatus']),
    cancelClick() {
      if (this.changeFlag) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }
      }
      this.jumpToListPage()
    },
    jumpToListPage() {
      console.log('cancel')
      this.$router.push({
        path: '/reserved-list',
        query: this.$route.params.query ?? {
        },
      })
    },
    closeReservedPreivew() {
      this.resvDialog = false
    },

    openOverviewDialog() {
      this.resvDialog = true
    },

    // getResvStatuses() {
    // console.log('resvList:', this.resvStatusList)
    // this.resvStatuses = this.resvStatusList.map(v => {
    //   const obj = {
    //   }
    //   obj.text = `${v.resv_stat}: ${v.resv_stat_desc}`
    //   obj.value = `${v.resv_stat}`

    //   return obj
    // })
    // },

    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        // this.loadReservationStatus(),
        this.loadReservedProgress(this.$route.params.id),
      ]).then(() => {
        // this.getResvStatuses()
        console.log('reservedProgress', this.reservedProgress)
        this.ProgressDetail = this.reservedProgress.details.map(r => {
          const obj = r
          if (r.proc_from === 1) {
            obj.proc_memo_cust = r.proc_memo
          } else {
            obj.proc_memo_admin = r.proc_memo
          }

          return obj
        })
      })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
        }).finally(() => {
          this.setOverlayStatus(false)
        })
    },

    submit() {
      this.apierror.messages = []
      this.submitStatus = true
      const submitForm = {
        resv_note: this.resv_note,
        resv_stat: this.resv_stat,
        resv_id: this.reservedProgress.resv_id,
      }
      this.editReserved(submitForm)
        .then(() => {
          this.submitStatus = false
          this.jumpToListPage()
        })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
        })
        .finally(() => {
          this.submitStatus = false
        })
    },
  },
}
</script>
